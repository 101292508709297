import type { GetBuybackShippingResponse } from '@backmarket/http-api/src/api-specs-buyback/customer/getBuybackShipping'

import { createHttpEndpoint } from '../../utils'

/**
 * Gives the available shippings for a given country and a specific sourcing offer. Caller must be an authenticated customer.
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-funnel-api/definition#/Public/get_shipping}
 */

export const getBuybackShippingV1 =
  createHttpEndpoint<GetBuybackShippingResponse>({
    method: 'GET',
    operationId: 'getBuybackShippingV1',
    path: '/buyback-funnel/api/v1/funnel/:kind/offer/:listingPublicId/shipping',
  })
