import type { GetBuybackShippingResponse } from '@backmarket/http-api/src/api-specs-buyback/customer/getBuybackShipping'
import type { Input } from '@backmarket/nuxt-layer-buyback/components/FormGenerator/FormGenerator.types'

export const useGenerateShippingForm = (
  shipping: GetBuybackShippingResponse | null,
): Array<Input> => {
  const shippingOptions = shipping?.map((option) => ({
    value: option.shippingId,
    label: option.title,
    description: option.text,
    disabled: option.isDisabled,
    tag: option.tag,
  }))
  const shippingDefaultValue =
    shipping?.find((option) => option.selected)?.shippingId || false

  return [
    /* SHIPPING MODE */
    {
      id: 'shipping-mode',
      cols: 6,
      value: shippingDefaultValue,
      type: 'radio-group',
      options: shippingOptions,
      validation: [],
    },
  ] as Array<Input>
}
